<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="isUpdate?$t('settings.system_roles.update'):$t('settings.system_roles.new')"
        :paths="[{title:$t('settings.title'),link:'settings'},{title:$t('settings.sys_users'),link:'system-users'},{title:$t('settings.sys_roles'),link:'system-roles'}]"
    />
    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-med-20 text-font-main mb-0">
                {{ $t('settings.system_roles.info') }}
              </h5>
            </b-card-header>
            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap" v-if="form.permissions.length>0 || !isUpdate">
                    <b-col cols="12" md="4">
                      <text-input
                          id="name"
                          v-model="form.name"
                          :label="$t('settings.system_roles.form.name')"
                          rules="required"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols="12" md="8">

                    </b-col>

                    <permission-section
                        section="orders"
                        :key="'orders'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'orders')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="assigned orders"
                        :key="'assigned orders'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'assigned orders')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="featured"
                        :key="'featured'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'featured')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="ads"
                        :key="'ads'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'ads')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="settings"
                        :key="'settings'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'settings')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="clients"
                        :key="'clients'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'clients')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="companies"
                        :key="'companies'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'companies')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="drivers"
                        :key="'drivers'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'drivers')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="reviews"
                        :key="'reviews'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'reviews')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="service_fees"
                        :key="'service_fees'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'service fees')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="discounts"
                        :key="'discounts'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'discounts')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="financials"
                        :key="'financials'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'financials')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="work_days"
                        :key="'work_days'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'work days')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="service_detail"
                        :key="'service_detail'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'service detail')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />
                    <permission-section
                        section="vehicle_types"
                        :key="'vehicle_types'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'vehicle types')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="classifications"
                        :key="'classifications'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'classifications')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="app messages"
                        :key="'app messages'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'app messages')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="admin_users"
                        :key="'admin_users'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'admin users')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="roles"
                        :key="'roles'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'roles')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                    <permission-section
                        section="chat"
                        :key="'chat'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'chat')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />

                    <permission-section
                        section="statements"
                        :key="'statements'+section_key"
                        :permissions="getPermissions.filter(item=>item.section === 'statements')"
                        :selected-permissions="form.permissions"
                        @onCheck="(id)=>onCheck(id)"
                    />


                  </div>


                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="isUpdate?$t('settings.system_roles.update'):$t('settings.system_roles.new')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextInput as TextInput,
  WameedCheckbox
} from 'wameed-ui/dist/wameed-ui.esm'
import PermissionSection from "@/views/pages/settings/system_roles/components/permissionSection";

export default {
  components: {
    PermissionSection,
    TextInput,
    WameedCheckbox,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      section_key: 1,
      form: {
        name: '',
        permissions: [],
      },

    };
  },
  computed: {
    ...mapGetters({
      getPermissions: 'admin/settings/sys_roles/getPermissions',
      detail: 'admin/settings/sys_roles/getDetail',
    }),
    isUpdate() {
      return (this.$route.name === 'system-roles-update' && this.$route.params.id);
    },

  },
  async created() {
    await this.$store.dispatch('admin/settings/sys_roles/getPermissions');
    if (this.$route.name === 'system-roles-update' && this.$route.params.id) {
      await this.$store.dispatch("admin/settings/sys_roles/getDetail", {id: this.$route.params.id})


      this.form = this.detail;
    } else {
      await this.$store.dispatch("admin/settings/sys_roles/unsetDetail");
    }
    this.section_key = this.section_key++
  },


  methods: {
    checkBoxLabel(name) {
      return this.$i18n.t("permissions." + name.replace("(", "_").replace(")", "").replaceAll(" ", "_").replaceAll("__", "_"))
    },

    goBack() {
      this.$router.push({name: 'system-roles'});
    },

    onCheck(id) {
      var data = this.form.permissions;
      if (data.includes(id)) {
        this.form.permissions = data.filter(i => i !== id);
      } else {
        this.form.permissions = [...data, id]
      }
    },

    submit() {


      if (this.$route.name === 'system-roles-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/settings/sys_roles/update', this.form);
      } else {
        this.$store.dispatch('admin/settings/sys_roles/create', this.form);
      }

    },
  }
  ,
}
;
</script>
